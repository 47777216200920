import React, { useContext, useEffect, useState } from 'react'
// import ViewUser from './user/ViewUser'
// import Footer from './footer/Footer'
import useApiAxios from "../api/useApiAxios.js";
import { Link } from 'react-router-dom';


const Dashboard = () => {

  const [dashBoardData,setDashBoardData ] = useState();
  const { getDashboardDataApi } = useApiAxios();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardDataApi();
        setDashBoardData(response.data.data.dashboardData);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, []);

  
  return (
    <div>
      <div className="main-panel">
        <div className="content">
          <div className="panel-header">
            <div className="page-inner py-5">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <div>
                  <h2 className="pb-2 fw-bold">Dashboard</h2>
                  <ul className="breadcrumbs">
                    <li className="nav-home">
                      <a href="#">
                        <i className="flaticon-home" />
                      </a>
                    </li>
                    <li className="separator">
                      <i className="flaticon-right-arrow" />
                    </li>
                    <li className="nav-item">
                      <Link to ="/">Dashboard</Link>
                    </li>
                  </ul>
                </div>
                {/* <div className="ml-md-auto py-2 py-md-0">
                  <div className="row">
                    <div className="col-6">
                      <input
                        type="text"
                        name="dates"
                        className="form-control pull-right"
                      />
                    </div>                  
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="page-inner mt--5">
            <div className="row">
              <div className="col-sm-6 col-md-3">
                <div className="card card-stats card-primary card-round">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-5">
                        <div className="icon-big text-center">
                          <i className="flaticon-users" />
                        </div>
                      </div>
                      <div className="col-7 col-stats">
                        <div className="numbers">
                          <p className="card-category">Total Vendors</p>
                          <h4 className="card-title">{dashBoardData?.totalVendors}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="card card-stats card-info card-round">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-5">
                        <div className="icon-big text-center">
                          <i className="flaticon-interface-6" />
                        </div>
                      </div>
                      <div className="col-7 col-stats">
                        <div className="numbers">
                          <p className="card-category">Total Markets</p>
                          <h4 className="card-title">{dashBoardData?.totalMarket}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-3">
                <div className="card card-stats card-success card-round">
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-5">
                        <div className="icon-big text-center">
                          <i className="flaticon-analytics" />
                        </div>
                      </div>
                      <div className="col-7 col-stats">
                        <div className="numbers">
                          <p className="card-category"> Total Sales</p>
                          <h4 className="card-title">$ {dashBoardData?.totalSales}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-6 col-md-3">
                <div className="card card-stats card-secondary card-round">
                  <div className="card-body ">
                    <div className="row">
                      <div className="col-5">
                        <div className="icon-big text-center">
                          <i className="flaticon-success" />
                        </div>
                      </div>
                      <div className="col-7 col-stats">
                        <div className="numbers">
                          <p className="card-category">Active Users</p>
                          <h4 className="card-title">576</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
           
          </div>
        </div>
        
      </div>
     
    </div>

  )
}

export default Dashboard